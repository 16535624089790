import styled from "styled-components";

export const Button = styled.div`
  width: 220px;
  height: 65px;
  background-color: white;
  font-size: 18px;
  text-transform: uppercase;
  color: #066e04;
  font-weight: 900;
  border-radius: 30px;
  border: 0px solid;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    background-color: green;
    color: white;
    color: lime;
    background-color: "#066E04";
  }
`;
