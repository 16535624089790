import { Box } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components";
import { Button } from "../styles/shared";
import logo from "../../images/logo.svg";

interface Props {}

export const Hero = (props: Props) => {
  return (
    <Box
      maxHeight={500}
      display="flex"
      alignContent="center"
      flexWrap="wrap"
      justifyContent="center"
      flexDirection="column"
      marginBottom={50}
      marginTop={50}
      style={{ textAlign: "center", gap: 0 }}
    >
      <img src={logo} style={{ width: 100, alignSelf: "center" }} />
      <br />
      <h1
        style={{
          alignSelf: "center",
          color: "lime",
          textAlign: "center",
          textTransform: "uppercase",
          maxWidth: 600,
          fontSize: 40,
        }}
      >
        finalmente disponibile il pacchetto{" "}
        <span style={{ color: "white", textDecoration: "underline" }}>PRO</span>
      </h1>
      <h2 style={{ color: "white", textAlign: "center" }}>
        *Attenzione: durante l'acquisto inserire l'email che si utilizza
        all'interno dell'app*
      </h2>
    </Box>
  );
};
