import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/core";
import { Button } from "../styles/shared.js";
import getStripe from "../../utils/stripejs";
interface Props {
  name?: string;
  prezzo?: any;
  price_id?: string;
}

export const Card = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: props.price_id, quantity: 1 }],
      successUrl: `${window.location.origin}/success/`,
      cancelUrl: `${window.location.origin}/`,
    });
    if (error) {
      console.warn("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Box
      minHeight={350}
      maxHeight={400}
      width={350}
      justifyContent="space-around"
      justifyItems="center"
      bg="lime"
      display="flex"
      flexDirection="column"
      style={{
        borderRadius: 14,
        boxShadow: "5px 7px 20px 0px rgba(239, 251, 163, 0.4)",
      }}
      rounded="true"
      boxShadow="true"
    >
      <h2
        style={{
          textAlign: "center",
          color: "#066E04",
          fontSize: 20,
          textTransform: "uppercase",
          fontWeight: 900,
        }}
      >
        {props.name}
      </h2>
      <h1
        style={{
          textAlign: "center",
          color: "#066E04",
          fontSize: 45,
          fontWeight: 900,
        }}
      >
        {props.prezzo / 100} €
      </h1>
      <Button onClick={(e: any) => handleSubmit(e)}>acquista ora</Button>
    </Box>
  );
};
