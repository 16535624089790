import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Card } from "../components/Card/Card";
import styled from "styled-components";
import { Box } from "@chakra-ui/core";
import { Hero } from "../components/Hero/Hero";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allStripePrice(sort: { fields: unit_amount }) {
        edges {
          node {
            product {
              id
              name
            }
            id
            currency
            billing_scheme
            unit_amount_decimal
            unit_amount
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Store" />
      <Hero />
      <CardsContainer>
        {data.allStripePrice.edges
          ? data.allStripePrice.edges.map(p => (
              <Card
                key={p.node.id}
                name={p.node.product.name}
                prezzo={p.node.unit_amount}
                price_id={p.node.id}
              />
            ))
          : ""}
      </CardsContainer>
      <Box display="flex" justifyContent="center" marginTop={10}>
        <p style={{ color: "white", textAlign: "center", fontWeight: 900 }}>
          <a
            rel="noreferrer"
            href="https://www.marcomoi.it/"
            target="_blank"
            style={{ color: "white" }}
          >
            Servizio Offerto e Creato da Innovoo ©
          </a>
        </p>
      </Box>
    </Layout>
  );
};

export default IndexPage;

const CardsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 50px;
  grid-template-columns: repeat(3, auto);

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
`;
