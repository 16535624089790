import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51HWdlVDLg8kWM4BQbaBCko8GJa2mUvTXBQ3ojLxAMUk23zKRWnrfPfhk3auWxss745ajhGZfdF6ktr9J2pjet4ZS00owoaCWgz"
    );
  }
  return stripePromise;
};
export default getStripe;
